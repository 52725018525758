<template>
  <v-container class="pa-0 text-caption font-weight-bold elevation-5">
    <v-row class="dark_blue text-center primary--text">
      <v-col cols="6" class="py-1" :style="{ border: 'solid thin #262737' }"
        ><span class="ma-auto"> Subtotal </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'solid thin #262737',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col cols="3" class="py-1" :style="{ border: 'solid thin #262737' }"
        ><span> {{ subTotal }} </span>
      </v-col>
    </v-row>
    <v-row class="primary text-center">
      <v-col
        cols="6"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span class="ma-auto"> Credits Applied </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'none',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span> ({{ credits }}) </span>
      </v-col>
    </v-row>

    <v-row class="primary text-center">
      <v-col
        cols="6"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span class="ma-auto"> Order Total </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'none',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span> {{ orderTotal }} </span>
      </v-col>
    </v-row>
    <v-row class="primary text-center">
      <v-col
        cols="6"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span class="ma-auto"> Payments Applied </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'none',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span> {{ payments }} </span>
      </v-col>
    </v-row>
    <v-row class="primary text-center">
      <v-col
        cols="6"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span class="ma-auto"> Refund Applied </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'none',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span> ({{ refund }}) </span>
      </v-col>
    </v-row>
    <v-row class="primary text-center">
      <v-col
        cols="6"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span class="ma-auto"> Order Balance </span>
      </v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{
          borderTop: 'none',
          borderBottom: 'solid thin #262737',
        }"
      ></v-col>
      <v-col
        cols="3"
        class="py-1"
        :style="{ border: 'solid thin #262737', borderTop: 'none' }"
        ><span> {{ OrderBal }} </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      creditData: (state) => state.moduleOrders.moduleDetails.creditData,
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      paymentDetails: (state) => state.moduleOrders.moduleDetails.paymentDetails,
    }),
    ...mapGetters({
      tabDetails: ["moduleOrders/tabDetails"],
      order_bal: ["moduleOrders/moduleDetails/order_bal"],
    }),
    OrderBal() {
      return (
        `${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(2)}` -
        `${parseFloat(this.order_bal).toFixed(2)}`
      );
    },
    payments() {
      let total = 0;
      this.paymentDetails
        .filter((x) => x.type === "PAYMENT")
        .map((x) => (total += Number(x.amount_paid)));

      return parseFloat(total).toFixed(2);
    },
    orderTotal() {
      return parseFloat(Number(this.subTotal) - Math.abs(Number(this.credits))).toFixed(
        2
      );
    },
    credits() {
      let total = 0;
      this.paymentDetails
        .filter((x) => x.type === "CREDIT")
        .map((x) => (total += Number(x.amount_paid)));
      return parseFloat(total).toFixed(2);
    },
    refund() {
      let total = 0;
      this.paymentDetails
        .filter((x) => x.type === "REFUND")
        .map((x) => (total += Number(x.amount_paid)));
      console.log(total);
      return parseFloat(total).toFixed(2);
    },
    subTotal() {
      let total = 0;
      this.lineItems.map((x) => (total += Number(x.buyerTotal)));
      return parseFloat(total).toFixed(2);
    },
  },
};
</script>

<style scoped></style>
